import utils from "./utils.index";
const korean = {
    toChars(str) {
        let cCho  = [ 'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ' ],
            cJung = [ 'ㅏ', 'ㅐ', 'ㅑ', 'ㅒ', 'ㅓ', 'ㅔ', 'ㅕ', 'ㅖ', 'ㅗ', 'ㅘ', 'ㅙ', 'ㅚ', 'ㅛ', 'ㅜ', 'ㅝ', 'ㅞ', 'ㅟ', 'ㅠ', 'ㅡ', 'ㅢ', 'ㅣ' ],
            cJong = [ '', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ' ],
            cho, jung, jong;

        let cnt = str.length,
            chars = [],
            cCode;

        for (let i = 0; i < cnt; i++) {
            cCode = str.charCodeAt(i);

            if (cCode === 32) { continue; }

            // 한글이 아닌 경우
            if (cCode < 0xAC00 || cCode > 0xD7A3) {
                chars.push(str.charAt(i));
                continue;
            }

            cCode  = str.charCodeAt(i) - 0xAC00;

            jong = cCode % 28; // 종성
            jung = ((cCode - jong) / 28 ) % 21; // 중성
            cho  = (((cCode - jong) / 28 ) - jung ) / 21; // 초성

            chars.push(cCho[cho], cJung[jung]);
            if (cJong[jong] !== '') { chars.push(cJong[jong]); }
        }

        return chars;
    },
    unionChars(cho, jung, jong) {
        let cCho  = [ 'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ' ],
            cJung = [ 'ㅏ', 'ㅐ', 'ㅑ', 'ㅒ', 'ㅓ', 'ㅔ', 'ㅕ', 'ㅖ', 'ㅗ', 'ㅘ', 'ㅙ', 'ㅚ', 'ㅛ', 'ㅜ', 'ㅝ', 'ㅞ', 'ㅟ', 'ㅠ', 'ㅡ', 'ㅢ', 'ㅣ' ],
            cJong = [ '', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ' ];

        let choCode, jungCode=0, jongCode=0;

        if("ㄱ" > cho || "ㅎ" < cho) return cho;

        if(utils.isNull(jung)) return cho;
        else
            jungCode = cJung.indexOf(jung);

        if(utils.isDefine(jong))
            jongCode = cJong.indexOf(jong);

        choCode = cCho.indexOf(cho);

        return String.fromCharCode((((choCode*21)+jungCode)*28)+jongCode+0xAC00);

    },
    typeArray(data) {
        if(utils.isArray(data)) {
            return data;
        } else {
            let list = [];

            for (let ch of data) {
                list.push(this.toChars(ch).length);
            }
            return list;
        }
    },
    extractInit(data){
        let list = [];

        for (let ch of data) {
            list.push(this.initial(ch));
        }
        return list.join('');
    },
    initial(kor)
    {
        return this.toChars(kor)[0];
    },
    middle(kor)
    {
        return this.toChars(kor)[1];
    },
    final(kor)
    {
        return this.toChars(kor)[2];
    },
    isInit(kor) {
        return !!this.initial(kor);
    },
    isInitAll(data){
        if(utils.isString(data)){
            data = this.typeArray(data);
        }
        return data.length > 0 && data.length === data.reduce((a,b)=>(a+b));
    },
    isComplete(kor) {
        return !this.isInit(kor);
    },
    isCompleteAll(data){
        if(utils.isString(data)){
            data = this.typeArray(data);
        }
        return !data.includes(1);
    },
    isIncluded(dest, search){
        let d=this.toChars(dest);
        let s=this.toChars(search);
        let dLen = d.length, sLen=s.length;

        if(dLen === 0 || sLen === 0)    return false;
        if(dLen === 3 && sLen === 2)    return false;
        if(dLen < sLen)                 return false;

        for(let i=0; i<dLen; i++){
            if(s[i] === undefined)      return true;
            if(d[i] !== s[i])           return false;
        }

        return true;
    }
}
const ARRAY = 0;
const STRING = 1;
const BOOLEAN = 2;
let search = {
    korean,
    matchingCheck(text, search, returnType) {
        text = text.replaceAll(' ', '')
        let matchedStr = "", matchIdx = -1;
        const sWordType = korean.typeArray(search);

        if(korean.isCompleteAll(sWordType)) {
            switch(returnType) {
                case BOOLEAN:
                    return text.includes(search);
                case STRING:
                    return text.includes(search) ? search : "";
                case ARRAY:
                    return text.includes(search) ? [search] : []
            }
        }
        else if(korean.isInitAll(sWordType)) {
            const eiText = korean.extractInit(text);
            const eiSearch = korean.extractInit(search);
            switch(returnType) {
                case BOOLEAN:
                    return eiText.includes(eiSearch);
                case STRING: case ARRAY:
                    matchIdx = eiText.indexOf(eiSearch);

                    if (matchIdx === -1) {
                        return returnType === STRING ? "" : [];
                    } else {
                        matchedStr = text.substr(matchIdx, search.length);
                        return returnType === STRING
                            ? matchedStr
                            : [matchedStr].concat(this.matchingCheck(text.substr(matchIdx + search.length), search, returnType));
                    }

            }
        }
        else {
            const eiText = korean.extractInit(text);
            const eiSearch = korean.extractInit(search);
            let stdPoint = 0, returnValue=[];
            do {
                matchIdx = eiText.substr(stdPoint).indexOf(eiSearch);
                if (matchIdx === -1) {
                    switch (returnType) {
                        case BOOLEAN:
                            return false;
                        case STRING:
                            return "";
                        case ARRAY:
                            return [];
                    }
                } else {
                    matchedStr = text.substr(stdPoint+matchIdx, search.length);
                    let match = true;
                    console.log(sWordType)
                    for(let i in sWordType){
                        if(sWordType[i] === 1) continue;
                        if(matchedStr[i] !== search[i]) {
                            match = false;
                            break;
                        }
                    }

                    if(match) {
                        switch (returnType) {
                            case BOOLEAN:
                                returnValue = match;
                                break;
                            case STRING:
                                returnValue = matchedStr;
                                break;
                            case ARRAY:
                                returnValue.push(matchedStr);
                                break;
                        }
                        if(returnType !== ARRAY) break;
                    }

                    stdPoint += matchIdx + search.length;

                }
            } while(matchIdx > -1 && text.length >= stdPoint + search.length);

            return returnValue;
        }
        /*
        let matchedStr = "";
        //검색어가 있을경우
        if(utils.isNotEmpty(search) && utils.isNotEmpty(text)){
            //검색어 일치
            if(text.includes(search)) {
                switch(returnType) {
                    case BOOLEAN:
                        return true;
                    case STRING:
                        return search;
                    case ARRAY:
                        return [search].concat(this.matchingCheck(text.substr(text.indexOf(search)+search.length), search, returnType));
                }
            }
            //검색어 불일치(이전글자까지 맞는경우)
            else if(search.length > 1 && text.includes(search.slice(0,-1))){
                matchedStr = search.slice(0,-1);
                let p = text.indexOf(matchedStr);
                let t = text.substr(p+matchedStr.length, 1);
                if(korean.isIncluded(t, search.slice(-1))) {
                    switch(returnType) {
                        case BOOLEAN:
                            return true;
                        case STRING:
                            return matchedStr + t
                        case ARRAY:
                            return [matchedStr + t].concat(this.matchingCheck(text.substr(p+matchedStr.length), search, returnType));
                    }
                }
                else {
                    return this.matchingCheck(text.substr(p+matchedStr.length), search, returnType)
                }
            } else {
                let sP=0;
                for(let i in text){
                    if(korean.isIncluded(text[i], search[sP])){
                        matchedStr += text[i];
                        sP++;
                        if(search[sP] === undefined) {
                            switch(returnType) {
                                case BOOLEAN:
                                    return true;
                                case STRING:
                                    return matchedStr
                                case ARRAY:
                                    return [matchedStr].concat(this.matchingCheck(text.substr(i+(sP===1?1:0)), search, returnType))
                            }
                        }
                    } else {
                        return this.matchingCheck(text.substr(i+(sP===0?1:0)), search, returnType)
                    }
                }

                switch(returnType) {
                    case BOOLEAN:
                        return false;
                    case STRING:
                        return "";
                    case ARRAY:
                        return [];
                }
            }
        } else {
            switch(returnType) {
                case BOOLEAN:
                    return false;
                case STRING:
                    return "";
                case ARRAY:
                    return [];
            }
        }
        */
    },
    matchedArray(text, search) {
        return utils.array.distinct(this.matchingCheck(text, search, ARRAY));
    },
    matchedString(text, search) {
        return this.matchingCheck(text, search, STRING);
    },
    isMatched(text, search) {
        return this.matchingCheck(text, search, BOOLEAN);
    },
    highlight(text, search, startTag, endTag) {
        if(utils.isArray(search)) {
            for(let s of search){
                text = text.replaceAll(s, startTag+s+endTag);
            }
        } else {
            text = text.replaceAll(search, startTag+search+endTag);
        }
        return text
    }

}
Object.freeze(search);
export default search;
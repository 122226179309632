import utils from "@/utils/utils.index";

let auth = {
    accessToken(token){
        if(token === undefined){
            return utils.storage.local('_accessToken');
        } else {
            utils.storage.local('_accessToken', token);
        }
    },
    refreshToken(token){
        if(token === undefined){
            return utils.storage.local('_refreshToken');
        } else {
            utils.storage.local('_refreshToken', token);
        }
    },
    access(){
        return utils.isNotEmpty(this.accessToken()) && utils.isNotEmpty(this.refreshToken());
    },
    accessInfo(info){
        if(info === undefined){
            return utils.storage.local('_accessInfo');
        } else {
            utils.storage.local('_accessInfo', info);
        }
    },
    clear(){
        this.accessToken(null);
        this.refreshToken(null);
        this.accessInfo(null);
    },
    logout(){
        this.clear();
        if(utils.isHybrid()) utils.native.snsLogout();
        else if(utils.isManage()){
            location.href='/manage/login';
        }
    },
    setExpireTime(ttl){
        if(ttl === undefined) ttl = 5;
        let now = new Date();
        now.setSeconds(now.getSeconds()+ttl);
        utils.storage.local('expireTime', now);
    },
    getExpireTime(){
        if(utils.isNull(utils.storage.local('expireTime'))) return null;
        else return new Date(utils.storage.local('expireTime'));
    },
    clearExpireTime(){
        utils.storage.local('expireTime', null);
    },
    isExpired(){
        const expireTime = this.getExpireTime();
        if(utils.isNull(expireTime)) return false;
        else return (expireTime - new Date() <= 0);
    }
}
Object.freeze(auth);
export default auth;
import http from "./utils.http"
import date from "./utils.date"
import search from "./utils.search"
import array from "./utils.array"
import format from "./utils.format"
import constant from "./utils.const"
import storage from "./utils.storage"
import certify from "./utils.certify";
import common from "./utils.common"
import file from "./utils.file";
import auth from "@/utils/utils.auth";
import check from "@/utils/utils.check";
import socket from "@/utils/utils.socket";

let Env = "";
let Vue = "";
const utils = Object.assign(
    {
            date, http, storage, search, array, format, const:constant,
            certify, file, auth, check, socket
    },
    common,
    {
        setEnv(env){
            Env = env;
            return this;
        },
        getEnv() {
            return Env;
        },
        isHybrid(){
            return this.getEnv() === "Hybrid";
        },
        isManage(){
            return this.getEnv() === "Manage";
        },
        setVue(vue) {
            Vue = vue;
            return this;
        },
        getVue(){
            return Vue;
        }
    }
)
Object.freeze(utils);
export default utils

import { createStore } from "vuex";
import bizInfo from './modules/bizInfo'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    bizInfo
  }
})

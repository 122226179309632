const common = {
    escapeHTML(str) {
        str = str.replace(/&amp;/g, "&");
        str = str.replace(/&lt;/g, "<");
        str = str.replace(/&gt;/g, ">");
        return str;
    },
    deleteHTML(string) {
        let objStrip = new RegExp();
        objStrip = /[<][^>]*[>]/gi;
        return string.replace(objStrip, "");
    },
    extractNumber(num) {
        return Number(String(num).replace(/[^-0-9]/g,''));
    },
    nl2br (text){
        return text.replaceAll('\n', '<br>');
    },
    fillZero(number, length){
        number = number + "";
        let num_length = number.length;
        for(let i=0; i<length - num_length; i++){
            number = "0"+number;
        }
        return number;
    },
    isNull(obj){
        return obj === null || obj === undefined;
    },
    isEmpty(obj){
        return (this.isNull(obj) || (obj === "") || obj === 0 || (this.isArray(obj) && obj.length === 0) || (this.isObject(obj) && Object.keys(obj).length === 0));
    },
    isNotEmpty(obj){
        return this.isEmpty(obj) === false;
    },
    isError(obj){
        return obj.error;
    },
    isNormal(obj){
        return this.isError(obj) === false;
    },

    isDefine(obj){
        return obj !== null && obj !== undefined;
    },
    isFunction(obj){
        return typeof obj === 'function';
    },
    isObject(obj){
        return typeof obj === "object" && this.isDefine(obj) && (!this.isArray(obj));
    },
    isArray(obj){
        return Array.isArray(obj);
    },
    isString(obj){
        return typeof obj === 'string';
    },
    isNumber(obj){
        return isFinite(obj) && obj !== false && obj !== true && obj !== null;
    },
    isNaN(obj){
        return isNaN(obj) && obj !== false && obj !== true && obj !== null;
    },
    deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    toJSON(obj){
        if(this.isNull(obj)) return "";
        else return JSON.stringify(obj);
    },
    fromJSON(obj){
        if(this.isNull(obj)) return null;
        else return JSON.parse(obj);
    },
    toBase64(obj) {
        if(this.isObject(obj)) obj = this.toJSON(obj);
        return btoa(obj);
    },
    fromBase64(obj, json) {
        obj = atob(obj);
        if(json) obj = this.fromJSON(obj);
        return obj;
    },
    dataString({mimetype, data}){
        return `data:${mimetype};base64,${data}`;
    },
    rand(from, to){
        to++;
        return Math.floor(Math.random()*(to-from))+from;
    },
    randCode(length){
        let code = '';
        for(let i=0; i<length; i++){
            let r = this.rand(0, 61);
            if(r < 10)          code += r;
            else if(r < 36)     code += String.fromCharCode(r+55);
            else                code += String.fromCharCode(r+61);
        }
        return code;
    }
}
Object.freeze(common);
export default common;

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index.vue')
    },

    {
        path: '/list',
        name: 'List',
        component: () => import('@/views/List.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router

import utils from "./utils.index";
let certify = {
    sendMobileCode(mobile, next){
        utils.http.post('certify/sendMobileCode', {
            mobile
        }).then(next)
    },
    confirmMobileCode(mobile, code, next){
        utils.http.post('certify/confirmMobileCode', {
            mobile, code
        }).then(next)
    },

    sendEmailCode(email, next){
        utils.http.post('certify/sendEmailCode', {
            email
        }).then(next)
    },
    confirmEmailCode(email, code, next){
        utils.http.post('certify/confirmEmailCode', {
            email, code
        }).then(next)
    },
}
Object.freeze(certify);
export default certify;
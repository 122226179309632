import utils from "./utils.index";
import postPos from "josa-js";

let check = {
    postPos(text, postpos){
        return postPos.c(text, postpos);
    },
    submit(vue, data, rule, count){
        console.log(data, rule, count);
        let submit = true;
        for(let key in data) {
            if(utils.isDefine(rule[key]) && utils.isDefine(rule[key][0])){
                for(let ruleCheck of rule[key]){
                    if(utils.isFunction(ruleCheck)){
                        if(ruleCheck(data[key]) !== true){
                            utils.dialog.alert(vue, ruleCheck(data[key]));
                            submit=false;
                            break;
                        }
                    }
                }

            }
            if(submit && utils.isDefine(count) && utils.isDefine(count[key]) && utils.isNumber(count[key])) {
                console.log(data[key].length, count[key])
                if(data[key].length > count[key]){
                    const label = vue.$refs[key].$refs.input.previousElementSibling.innerText;
                    console.log(label);
                    utils.dialog.alert(vue, `${label}${this.postPos(label, "은/는")} ${count[key]}자 이상 입력할 수 없습니다.`);
                    submit=false;
                }
            }

            if(submit === false){
                vue.$refs[key].$refs.input.focus()
                console.log(vue.$refs[key].$refs.input)
                vue.$refs[key].$refs.input.focus()
                global.v = vue;

                break;
            }
        }
        return submit;
    }
}
Object.freeze(check);
export default check;
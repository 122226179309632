import common from "./utils.common";

let array = {
    sum(arr){
        if(common.isArray(arr)){
            return arr.reduce((a,b)=>(a+b));
        } else {
            return 0;
        }
    },
    average(arr) {
        if(common.isArray(arr)){
            return this.sum(arr) / arr.length;
        } else {
            return 0;
        }
    },
    distinct(array){
        const set = new Set(array);
        return [...set];
    },
    equals(arr1, arr2){
        return arr1.filter(x=>(arr2.includes(x))).length === arr2.length &&
            arr1.length === arr2.length;
    }
}
Object.freeze(array);
export default array;
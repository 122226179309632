import utils from "@/utils/utils.index";

export default {
    namespaced: true,
    state: {
        myInfo: {}
    },
    mutations: {
        setMyInfo(state, info) {
            state.myInfo = info;
        },
        setMyCard(state, cardNo) {
            state.myCard = cardNo;
        },
    },
    actions: {
        loadMyInfo({commit}, next) {
            utils.http.post('bizcard/myinfo/load').then((res)=>{
                commit('setMyInfo', res.data.myInfo);
                if(utils.isFunction(next)) next(res.data.myInfo);
            });
        },
        saveMyInfo({commit}, {bizList, next}) {
            utils.http.post('bizcard/myinfo/save', {bizList}).then((res)=>{
                commit('setMyInfo', res.data.myInfo);
                if(utils.isFunction(next)) next(res.data.myInfo);
            });
        },
    }
}

import axios from 'axios';
import utils from "./utils.index";
import constant from "./utils.const";

let URLOption = {
    baseURL: "",
    withBase:true,
}
const http = {
    statusCode: constant.statusCode,
    ERR_NOT_DEFINE_METHOD: 101,
    setBaseURL(url){
        URLOption.baseURL = url;
    },
    getBaseURL(){
        return URLOption.baseURL;
    },
    withoutBaseURL(){
        URLOption.withBase = false;
    },
    send(method, url, data, params, headers){
        return new Promise((resolve, reject) => {
            if(URLOption.withBase) url = URLOption.baseURL + url;

            let sendPromise = null;
            if(method.toLowerCase() === "get"){
                params = Object.assign({}, params, data);
                sendPromise = axios.get(url, {params, headers});
            } else if(method.toLowerCase() === "post") {
                sendPromise = axios.post(url, data, {params, headers});
            } else if(method.toLowerCase() === "delete") {
                sendPromise = axios.delete(url, {params, headers});
            }

            if(URLOption.withBase === false) URLOption.withBase = true;

            if(sendPromise === null) {
                reject({
                    error: true,
                    errCode: http.ERR_NOT_DEFINE_METHOD
                })
            } else {
                sendPromise.then((res) => {
                    resolve(res);
                }).catch((error)=>{
                    reject(error)
                })
            }

        })
    },
    get(url, data, params, headers){
        return this.send("get", url, data, params, headers);
    },
    post(url, data, params, headers){
        return this.send("post", url, data, params, headers);
    },
    upload(url, data, params, headers){
        let formData = new FormData();
        for(let name in data){
            if(utils.isArray(data[name])){
                data[name].forEach((obj, idx)=>{
                    formData.append(`${name}`, data[name][idx]);
                })
            } else {
                formData.append(name, data[name]);
            }
        }
        headers = Object.assign({
            'Content-Type': 'multipart/form-data'
        }, headers);
        return this.post(url, formData, params, headers)
    },
    setDefaultHeaders(headers){
        Object.assign(axios.defaults.headers.common, headers);
    },
    delDefaultHeaders(...headerKeys){
        headerKeys.forEach((key)=>{
            if(utils.isString(key)) {
                delete axios.defaults.headers.common[key];
            }
        })
    },
}
Object.freeze(http);

export default http;
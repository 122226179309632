import utils from "./utils.index";
let sock=null;
let socket = {
    set(socket, listener){
        sock = socket;
        sock.on('client', ({to, type, data})=> {
            if(utils.isFunction(listener)) listener({to, type, data});
        });
    },
    send({to, type, data}){
        sock.emit('server', {to, type, data});
    },
}
Object.freeze(socket);
export default socket;